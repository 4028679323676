@import './style/normalize.scss';
@import './style/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
html {
  overflow-x: hidden;
  height: 100vh;
}
body {
  height: auto;
  margin: 0;

  color: $textColor;
  overflow-x: hidden;

  font-family: 'Montserrat', sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
}

.no_scroll {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  transition: 0.5s;

  background-color: $heroBgc;
}

@media screen and (max-width: ($mobilWidth - 1px)) {
  .container {
    width: 100%;
  }
}

@media screen and (min-width: $mobilWidth) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: $mobilWidth) and (max-width: ($mobilBigWidth - 1px)) {
  .container {
    max-width: $mobilWidth;
  }
}

@media screen and (min-width: $mobilBigWidth) and (max-width: ($tabletWidth - 1px)) {
  .container {
    max-width: $mobilBigWidth;
  }
}

@media screen and (min-width: $tabletWidth) and (max-width: ($tabletBigWidth - 1px)) {
  .container {
    max-width: $tabletWidth;
  }
}

@media screen and (min-width: $tabletBigWidth) and (max-width: ($desktopWidth - 1px)) {
  .container {
    max-width: $tabletBigWidth;
  }
}

@media screen and (min-width: $desktopWidth) {
  .container {
    max-width: $desktopWidth;
  }
}
